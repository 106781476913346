import { ChangeDetectionStrategy, Component, inject, input, InputSignal, signal, WritableSignal } from '@angular/core';

import { ModalService } from '../../services/modal.service';
import { NgClass } from '@angular/common';
import { animate, style, transition, trigger } from '@angular/animations';
import { ClickOutside } from 'ngxtension/click-outside';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrl: './modal.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [NgClass, ClickOutside],
  animations: [
    trigger('slidein', [
      transition(':enter', [
        style({
          transform: 'translateY(100%)',
        }),
        animate(
          '250ms ease',
          style({
            transform: 'translateY(0)',
          })
        ),
      ]),
      transition(':leave', [
        animate(
          '250ms ease',
          style({
            transform: 'translateY(100%)',
          })
        ),
      ]),
    ]),
  ],
})
export class ModalComponent<T> {
  public wide: InputSignal<boolean> = input<boolean>(false);
  public bottomSheet: InputSignal<boolean> = input<boolean>(false);
  public readonly display: WritableSignal<boolean> = signal<boolean>(true);
  public readonly displayBottomSheet: WritableSignal<boolean> = signal<boolean>(true);
  private readonly modalService: ModalService<T> = inject(ModalService<T>);

  public close(): void {
    if (this.bottomSheet()) {
      this.displayBottomSheet.set(false);

      setTimeout((): void => {
        this.display.set(false);

        setTimeout((): void => {
          this.modalService.close();
        }, 250);
      }, 250);
    } else {
      this.display.set(false);

      setTimeout((): void => {
        this.modalService.close();
      }, 250);
    }
  }
}
