<section [class.open]="display()" class="fixed z-10 inset-0 overflow-y-auto">
  <div
    class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:p-0 bg-gray-900 bg-opacity-40">
    <div aria-hidden="true" class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>
    <span aria-hidden="true" class="hidden sm:inline-block sm:align-middle sm:h-screen">&#8203;</span>

    @if (bottomSheet()) {
      @if (displayBottomSheet()) {
        <div @slidein class="fixed w-full bottom-0 px-2 max-h-[75%] overflow-auto" (clickOutside)="close()">
          <div class="max-h-max p-6 bg-gray-100 dark:bg-slate-800 rounded-t-xl text-left">
            <button class="close-btn text-gray-900 dark:text-gray-100" (click)="close()">
              &times;
            </button>
            <div class="text-gray-900 dark:text-gray-100">
              <ng-content select="[slot=bottom]" />
            </div>
          </div>
        </div>
      }
    } @else {
      <div
        (click)="$event.stopPropagation()"
        [ngClass]="{ 'sm:max-w-4xl': wide(), 'sm:max-w-lg': !wide() }"
        aria-labelledby="modal-headline"
        aria-modal="true"
        class="inline-block align-bottom bg-gray-100 dark:bg-slate-900 rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle max-w-xl sm:w-full sm:p-6"
        role="dialog">
        <ng-content select="[slot=popup]" />
      </div>
    }
  </div>
</section>
